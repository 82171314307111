import {
  IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonButton, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonTitle, IonToolbar, IonToast, IonSpinner, IonToggle, IonRefresher, IonRefresherContent
} from '@ionic/react';
import { book, build, colorFill, grid } from 'ionicons/icons';
import React, { useState, useEffect, Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import './Style.css';
import SeatMap from './SeatMap';
import { create } from 'ionicons/icons';
import { RefresherEventDetail } from '@ionic/core';
import axios from 'axios';

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export default class Tab2 extends Component<any, any> {

  constructor(props: {}) {
    super(props);
    this.state = {
      token: JSON.parse(window.localStorage.getItem('token') as string),
      settings: {
        today: false,
        map: false,
        success: false,
        error: false,
        loading: false,
        toast: false,
        toastMessage: null,
        edit: false,
        user: {},
      },
      booking: {
        seat: null,
        place: null,
        morning: null,
        afternoon: null
      }
    }

    // When initiated again
    this.props.history.listen((location, action) => {
      if (this.props.history.location.search === "?edit=today") {
        this.editBooking('today');
      }
      if (this.props.history.location.search === "?edit=tomorrow") {
        this.editBooking('tomorrow');
      }
    });
  }

  componentDidMount() {
    this.getUser()

    if (this.props.history.location.search === "?edit=today") {
      this.editBooking('today');
    }
    if (this.props.history.location.search === "?edit=tomorrow") {
      this.editBooking('tomorrow');
    }
  }

  refresh = (event: CustomEvent<RefresherEventDetail>) => {
    this.getUser();
    setTimeout(() => {
      event.detail.complete();
    }, 500);
  }

  user() {
    return this.state.settings.user;
  }

  getUser() {
    let vm = this;

    axios.get(
      'https://sitz.senn.com/api/v1/user',
      {
        headers: {
          'Authorization': 'Bearer ' + this.state.token.access_token,
          'Content-Type': 'application/json'
        }
      }
    ).then(res => {
      this.state.settings.user = res.data
      this.setState(this.state);
    })
      .catch(function (error) {
        console.log(error)
        if (error.response.status === 401) {
        }
        vm.state.settings.error = true;
        window.localStorage.removeItem('token');
        vm.setState(vm.state);
      })
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  selectSeat(seat) {
    if (!seat.enabled) {
      return
    }
    if (seat.booking.length > 0) {
      return
    }
    this.state.booking.seat = seat;
    this.setState(this.state);
    //this.setState({ booking: { seat: seat } });
  }

  setPlace(place) {

    this.state.booking.place = place;
    this.setState(this.state);

    if (this.state.settings.today && this.state.settings.user.today) {
      this.state.settings.map = true;
      this.setState(this.state);
      return;
    }

    if (!this.state.settings.today && this.state.settings.user.booking) {
      this.state.settings.map = true;
      this.setState(this.state);
      return;
    }

    if (place != 'it\'s complicated') {
      this.saveBooking()
    }
  }

  setMorning(place) {
    this.state.booking.morning = place;
    this.setState(this.state);
  }

  setAfternoon(place) {
    this.state.booking.afternoon = place;
    this.setState(this.state);
  }

  showMap(value) {
    // console.log('setmap', value)
    // console.log('setmap', this.state.settings)
  }

  editBooking(date) {
    this.state.settings.edit = true;
    if (date == 'today') {
      this.state.settings.today = true;
    } else {
      this.state.settings.today = false;
    }

    this.setState(this.state);
  }

  closeToast() {
    this.state.settings.toast = false;
    this.setState(this.state);
  }

  showToast() {
    this.state.settings.toast = true;
    this.setState(this.state);
  }

  resetState() {
    this.state.booking.seat = null
    this.state.booking.place = null
    this.state.booking.morning = null
    this.state.booking.afternoon = null
    this.state.settings.loading = false
    this.state.settings.edit = false

    this.setState(this.state)
  }

  async saveBooking() {
    this.state.settings.loading = true;
    this.setState(this.state);
    let vm = this;
    await axios.post(
      'https://sitz.senn.com/api/v1/bookings',
      { booking: this.state.booking, today: this.state.settings.today },
      {
        headers: {
          'Authorization': 'Bearer ' + this.state.token.access_token,
          'Content-Type': 'application/json'
        }
      }
    ).then(res => {
      this.resetState()
      if (this.state.settings.today) {
        this.props.history.push('/dashboard?tab=today')
      } else {
        this.props.history.push('/dashboard?tab=tomorrow')
      }
    })
      .catch(function (error) {
        console.log(error)
        if (error.response.status === 401) {
        }

        if (error.response.status === 422) {
          vm.state.settings.toastMessage = error.response.data.message;
          vm.state.settings.toast = true;
          vm.state.settings.loading = false;
          vm.setState(vm.state);
          return;
        }

        vm.state.settings.error = true;
        window.localStorage.removeItem('token');
        vm.setState(vm.state);

        console.log(error.toJSON());
      })
  }
  isWeekend() {
    var today = new Date();
    if (today.getDay() == 6 || today.getDay() == 0) {
      return true;
    }
    return false;
  }
  getToday() {
    return "Heute";
  }

  getTomorrow() {
    var today = new Date();

    if (today.getDay() == 6 || today.getDay() == 5 || today.getDay() == 0) {
      return 'Montag';
    }

    return "Morgen";
  }
  getTodaySmall() {
    return "heute";
  }
  getTomorrowSmall() {
    var today = new Date();

    if (today.getDay() == 6 || today.getDay() == 5 || today.getDay() == 0) {
      return 'Montag';
    }

    return "morgen";
  }

  render() {
    const user = this.user();
    const booking = this.state.booking;

    if (!this.state.token || this.state.settings.error) {
      return <Redirect to='/login' />
    }

    // if (this.state.settings.success) {
    //   return <Redirect to='/dashboard' />
    // }

    return (
      <>
        <IonPage>

          <IonToast
            color="danger"
            isOpen={this.state.settings.toast}
            onDidDismiss={() => this.closeToast()}
            message={this.state.settings.toastMessage}
            duration={2000}
            position="top"
          />

          <IonContent>
            <div className="senn-header tab-2">
              <a className="senn-logo" href="/dashboard"><img src="/assets/sennlogo.svg" style={{ height: '116' }} width="116px" /></a>
            </div>

            <IonRefresher slot="fixed" onIonRefresh={this.refresh}>
              <IonRefresherContent refreshingSpinner="dots"></IonRefresherContent>
            </IonRefresher>

            <div>
              {user.today &&
                <IonCard className="no-radius" color="secondary" button={true} onClick={() => this.editBooking('today')}>
                  <IonCardHeader>
                    <IonCardSubtitle>{this.getToday()}</IonCardSubtitle>
                    <IonCardTitle className="card-title">
                      {(user.today.place != 'it\'s complicated') &&
                        <div>
                          {user.today.place == 'corso' &&
                            <span>Am </span>
                          }
                          {user.today.place == 'zwicky' &&
                            <span>Im </span>
                          }
                          {capitalize(user.today.place)}
                        </div>
                      }
                      {(user.today.place == 'it\'s complicated') &&
                        <div>
                          <div className="card-title-seat">
                            {(user.today.booking.morning !== 'corso' && user.today.booking.morning !== 'zwicky') ?
                              <div>Du arbeitest am Morgen {user.today.booking.morning}.</div>
                              : <div>
                                <span>Du arbeitest am Morgen
                                  {user.today.booking.morning == 'corso' &&
                                    <span> am </span>
                                  }
                                  {user.today.booking.morning == 'zwicky' &&
                                    <span> im </span>
                                  }
                                  {capitalize(user.today.booking.morning)} am Platz </span>
                                {(user.today.booking.morning === 'zwicky') &&
                                  <span>{user.today.seats.find(function (element) {
                                    return element.workplace_id === 2;
                                  }).name}</span>
                                }
                                {(user.today.booking.morning === 'corso') &&
                                  <span>{user.today.seats.find(function (element) {
                                    return element.workplace_id === 1;
                                  }).name}</span>
                                }.
                              </div>
                            }
                          </div>
                          <div className="card-title-seat">
                            {(user.today.booking.afternoon !== 'corso' && user.today.booking.afternoon !== 'zwicky') ?
                              <div>Du arbeitest am Nachmittag {user.today.booking.afternoon}.</div>
                              : <div>
                                <span>Du arbeitest am Nachmittag
                                {user.today.booking.afternoon == 'corso' &&
                                    <span> am </span>
                                  }
                                  {user.today.booking.afternoon == 'zwicky' &&
                                    <span> im </span>
                                  }
                                  {capitalize(user.today.booking.afternoon)} am Platz </span>
                                {(user.today.booking.afternoon === 'zwicky') &&
                                  <span>{user.today.seats.find(function (element) {
                                    return element.workplace_id === 2;
                                  }).name}</span>
                                }
                                {(user.today.booking.afternoon === 'corso') &&
                                  <span>{user.today.seats.find(function (element) {
                                    return element.workplace_id === 1;
                                  }).name}</span>
                                }.
                              </div>
                            }
                          </div>
                        </div>
                      }
                      {(user.today.booking && user.today.booking.seat && user.today.booking.seat.name) &&
                        <div className="card-title-seat">
                          Du arbeitest am Platz {user.today.booking.seat.name}.
                      </div>
                      }
                    </IonCardTitle>
                    <IonIcon className="card-icon" icon={create}></IonIcon>
                  </IonCardHeader>
                </IonCard>
              }
              {(!this.isEmpty(user) && !user.today && !this.isWeekend()) &&
                <IonCard className="no-radius" color="default" button={true} onClick={() => this.editBooking('today')}>
                  <IonCardHeader>
                    <IonCardSubtitle>{this.getToday}</IonCardSubtitle>
                    <IonCardTitle>
                      Noch kein Platz gebucht.
                    </IonCardTitle>
                    <IonIcon className="card-icon" icon={create}></IonIcon>
                  </IonCardHeader>
                </IonCard>
              }

              {(user.booking && !this.state.settings.today) &&
                <IonCard className="no-radius" color="secondary" button={true} onClick={() => this.editBooking('tomorrow')}>
                  <IonCardHeader>
                    <IonCardSubtitle>{this.getTomorrow()}</IonCardSubtitle>
                    <IonCardTitle className="card-title">
                      {(user.booking.place != 'it\'s complicated') &&
                        <div>
                          {user.booking.place == 'corso' &&
                            <span>Am </span>
                          }
                          {user.booking.place == 'zwicky' &&
                            <span>Im </span>
                          }
                          {capitalize(user.booking.place)}
                        </div>
                      }
                      {(user.booking.place == 'it\'s complicated') &&
                        <div>
                          <div className="card-title-seat">
                            {(user.booking.booking.morning !== 'corso' && user.booking.booking.morning !== 'zwicky') ?
                              <div>Du arbeitest am Morgen {user.booking.booking.morning}.</div>
                              : <div>
                                <span>Du arbeitest am Morgen
                              {user.booking.booking.morning == 'corso' &&
                                    <span> am </span>
                                  }
                                  {user.booking.booking.morning == 'zwicky' &&
                                    <span> im </span>
                                  }
                                  {capitalize(user.booking.booking.morning)} am Platz </span>
                                {(user.booking.booking.morning === 'zwicky') &&
                                  <span>{user.booking.seats.find(function (element) {
                                    return element.workplace_id === 2;
                                  }).name}</span>
                                }
                                {(user.booking.booking.morning === 'corso') &&
                                  <span>{user.booking.seats.find(function (element) {
                                    return element.workplace_id === 1;
                                  }).name}</span>
                                }.
                            </div>
                            }
                          </div>
                          <div className="card-title-seat">
                            {(user.booking.booking.afternoon !== 'corso' && user.booking.booking.afternoon !== 'zwicky') ?
                              <div>Du arbeitest am Nachmittag {user.booking.booking.afternoon}.</div>
                              : <div>
                                <span>Du arbeitest am Nachmittag
                              {user.booking.booking.afternoon == 'corso' &&
                                    <span> am </span>
                                  }
                                  {user.booking.booking.afternoon == 'zwicky' &&
                                    <span> im </span>
                                  }
                                  {capitalize(user.booking.booking.afternoon)} am Platz </span>
                                {(user.booking.booking.afternoon === 'zwicky') &&
                                  <span>{user.booking.seats.find(function (element) {
                                    return element.workplace_id === 2;
                                  }).name}</span>
                                }
                                {(user.booking.booking.afternoon === 'corso') &&
                                  <span>{user.booking.seats.find(function (element) {
                                    return element.workplace_id === 1;
                                  }).name}</span>
                                }.
                            </div>
                            }
                          </div>
                        </div>
                      }
                      {(user.booking.booking && user.booking.booking.seat && user.booking.booking.seat.name) &&
                        <div className="card-title-seat">
                          Du arbeitest am Platz {user.booking.booking.seat.name}.
                      </div>
                      }
                    </IonCardTitle>
                    <IonIcon className="card-icon" icon={create}></IonIcon>
                  </IonCardHeader>
                </IonCard>
              }
              {(!this.isEmpty(this.state.settings.user) && !user.booking && !this.state.settings.today) &&
                <IonCard className="no-radius" color="default" button={true} onClick={() => this.editBooking('tomorrow')}>
                  <IonCardHeader>
                    <IonCardSubtitle>{this.getTomorrow()}</IonCardSubtitle>
                    <IonCardTitle>
                      Noch kein Platz gebucht.
                    </IonCardTitle>
                    <IonIcon className="card-icon" icon={create}></IonIcon>
                  </IonCardHeader>
                </IonCard>
              }
            </div>

            {(!this.state.settings.success && this.state.settings.edit) &&
              <div className="content">
                <h1 className="text-black text-4xl text-90 font-light my-8">
                  Wo wirst du {this.state.settings.today ? ' ' + this.getTodaySmall() + ' ' : ' ' + this.getTomorrowSmall() + ' '} arbeiten?
            </h1>

                <IonButton className="custom-button" expand="block" color={booking.place == 'corso' ? 'secondary' : 'light'} onClick={() => this.setPlace('corso')}>Am Corso</IonButton>
                <IonButton className="custom-button" expand="block" color={booking.place == 'zwicky' ? 'secondary' : 'light'} onClick={() => this.setPlace('zwicky')}>Im Zwicky</IonButton>
                <IonButton className="custom-button" expand="block" color={booking.place == 'unterwegs' ? 'secondary' : 'light'} onClick={() => this.setPlace('unterwegs')}>Unterwegs</IonButton>
                <IonButton className="custom-button" expand="block" color={booking.place == 'zuhause' ? 'secondary' : 'light'} onClick={() => this.setPlace('zuhause')}>Home Office</IonButton>
                <IonButton className="custom-button" expand="block" color={booking.place == 'it\'s complicated' ? 'secondary' : 'light'} onClick={() => this.setPlace('it\'s complicated')}>It's complicated</IonButton>

                {(this.state.settings.edit && (booking.place == 'zwicky' || booking.place == 'corso')) &&
                  <div>
                    {(this.state.settings.map) &&
                      <div>
                        <SeatMap today={this.state.settings.today} place={booking.place} seat={booking.seat} selectSeat={this.selectSeat.bind(this)} />
                      </div>
                    }
                  </div>
                }

                {booking.place == 'it\'s complicated' &&
                  <div>
                    <p>Wo wirst du am Morgen arbeiten?</p>
                    <IonButton className="custom-button" expand="block" color={booking.morning == 'corso' ? 'secondary' : 'light'} onClick={() => this.setMorning('corso')}>Am Corso</IonButton>
                    <IonButton className="custom-button" expand="block" color={booking.morning == 'zwicky' ? 'secondary' : 'light'} onClick={() => this.setMorning('zwicky')}>Im Zwicky</IonButton>
                    <IonButton className="custom-button" expand="block" color={booking.morning == 'unterwegs' ? 'secondary' : 'light'} onClick={() => this.setMorning('unterwegs')}>Unterwegs</IonButton>
                    <IonButton className="custom-button" expand="block" color={booking.morning == 'zuhause' ? 'secondary' : 'light'} onClick={() => this.setMorning('zuhause')}>Home Office</IonButton>

                    <p>Wo wirst du am Nachmittag arbeiten?</p>
                    <IonButton className="custom-button" expand="block" color={booking.afternoon == 'corso' ? 'secondary' : 'light'} onClick={() => this.setAfternoon('corso')}>Am Corso</IonButton>
                    <IonButton className="custom-button" expand="block" color={booking.afternoon == 'zwicky' ? 'secondary' : 'light'} onClick={() => this.setAfternoon('zwicky')}>Im Zwicky</IonButton>
                    <IonButton className="custom-button" expand="block" color={booking.afternoon == 'unterwegs' ? 'secondary' : 'light'} onClick={() => this.setAfternoon('unterwegs')}>Unterwegs</IonButton>
                    <IonButton className="custom-button" expand="block" color={booking.afternoon == 'zuhause' ? 'secondary' : 'light'} onClick={() => this.setAfternoon('zuhause')}>Home Office</IonButton>
                  </div>
                }

                {((!this.state.settings.loading && booking.place == 'it\'s complicated') || this.state.settings.map) &&
                  <IonButton className="custom-button" color="success" expand="block" onClick={() => this.saveBooking()}>Buchen</IonButton>
                }

                {this.state.settings.loading &&
                  <IonSpinner name="dots" />
                }
              </div>
            }


            {(user.booking && this.state.settings.today) &&
              <IonCard className="no-radius" color="secondary" button={true} onClick={() => this.editBooking('tomorrow')}>
                <IonCardHeader>
                  <IonCardSubtitle>{this.getTomorrow()}</IonCardSubtitle>
                  <IonCardTitle className="card-title">
                    {(user.booking.place != 'it\'s complicated') &&
                      <div>
                        {user.booking.place == 'corso' &&
                          <span>Am </span>
                        }
                        {user.booking.place == 'zwicky' &&
                          <span>Im </span>
                        }
                        {capitalize(user.booking.place)}
                      </div>
                    }
                    {(user.booking.place == 'it\'s complicated') &&
                      <div>
                        <div className="card-title-seat">
                          {(user.booking.booking.morning !== 'corso' && user.booking.booking.morning !== 'zwicky') ?
                            <div>Du arbeitest am Morgen {user.booking.booking.morning}.</div>
                            : <div>
                              <span>Du arbeitest am Morgen
                            {user.booking.booking.morning == 'corso' &&
                                  <span> am </span>
                                }
                                {user.booking.booking.morning == 'zwicky' &&
                                  <span> im </span>
                                }
                                {capitalize(user.booking.booking.morning)} am Platz </span>
                              {(user.booking.booking.morning === 'zwicky') &&
                                <span>{user.booking.seats.find(function (element) {
                                  return element.workplace_id === 2;
                                }).name}</span>
                              }
                              {(user.booking.booking.morning === 'corso') &&
                                <span>{user.booking.seats.find(function (element) {
                                  return element.workplace_id === 1;
                                }).name}</span>
                              }.
                          </div>
                          }
                        </div>
                        <div className="card-title-seat">
                          {(user.booking.booking.afternoon !== 'corso' && user.booking.booking.afternoon !== 'zwicky') ?
                            <div>Du arbeitest am Nachmittag {user.booking.booking.afternoon}.</div>
                            : <div>
                              <span>Du arbeitest am Nachmittag
                            {user.booking.booking.afternoon == 'corso' &&
                                  <span> am </span>
                                }
                                {user.booking.booking.afternoon == 'zwicky' &&
                                  <span> im </span>
                                }
                                {capitalize(user.booking.booking.afternoon)} am Platz </span>
                              {(user.booking.booking.afternoon === 'zwicky') &&
                                <span>{user.booking.seats.find(function (element) {
                                  return element.workplace_id === 2;
                                }).name}</span>
                              }
                              {(user.booking.booking.afternoon === 'corso') &&
                                <span>{user.booking.seats.find(function (element) {
                                  return element.workplace_id === 1;
                                }).name}</span>
                              }.
                          </div>
                          }
                        </div>
                      </div>
                    }
                    {(user.booking.booking && user.booking.booking.seat && user.booking.booking.seat.name) &&
                      <div className="card-title-seat">
                        Du arbeitest am Platz {user.booking.booking.seat.name}.
                      </div>
                    }
                  </IonCardTitle>
                  <IonIcon className="card-icon" icon={create}></IonIcon>
                </IonCardHeader>
              </IonCard>
            }

            {(!this.isEmpty(this.state.settings.user) && !user.booking && this.state.settings.today) &&
              <IonCard className="no-radius" color="default" button={true} onClick={() => this.editBooking('tomorrow')}>
                <IonCardHeader>
                  <IonCardSubtitle>{this.getTomorrow()}</IonCardSubtitle>
                  <IonCardTitle>
                    Noch kein Platz gebucht.
                    </IonCardTitle>
                  <IonIcon className="card-icon" icon={create}></IonIcon>
                </IonCardHeader>
              </IonCard>
            }

          </IonContent>
        </IonPage>
      </>
    );
    // <span onClick={() => {this.state.showMap = true; this.setState(this.state); }}>Platz selber wählen</span>
  }
};
