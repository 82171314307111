import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { calendar, home, person } from 'ionicons/icons';
import Tab1 from './pages/Tab1';
import Login from './pages/Login';
import Register from './pages/Register';
import Tab2 from './pages/Tab2';
import Tab3 from './pages/Tab3';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';


import { Plugins, PushNotificationActionPerformed } from '@capacitor/core';
const { PushNotifications } = Plugins;

const App: React.FC = () => {

  useEffect(() => {
    
    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: PushNotificationActionPerformed) => {
        console.log('Push action performed: ' + JSON.stringify(notification));
        if (window.location.pathname != '/buchung' || window.location.search != '?edit=tomorrow') {
          window.location.href = '/buchung?edit=tomorrow';
        }
      }
    );
  });
  
  return (
  <IonApp>
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet>
          <Route path="/login" component={Login} exact={true} />
          <Route path="/registrieren" component={Register} exact={true} />
          <Route path="/dashboard" component={Tab1} exact={true} />
          <Route path="/buchung" component={Tab2} exact={true} />
          <Route path="/profil" component={Tab3} />
          <Route path="/" render={() => <Redirect to="/login" />} exact={true} />
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton tab="tab1" href="/dashboard">
            <div className="senn-square"></div>
            <IonLabel>Home</IonLabel>
          </IonTabButton>
          <IonTabButton tab="tab2" href="/buchung">
            <IonIcon icon={calendar} />
            <IonLabel>Buchung ändern</IonLabel>
          </IonTabButton>
          <IonTabButton tab="tab3" href="/profil">
            <IonIcon icon={person} />
            <IonLabel>Account</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>
  </IonApp>
);

}


export default App;
