import React, { Component, useState } from 'react';
import { IonPopover, IonButton, IonSpinner, IonToast } from '@ionic/react';

import axios from 'axios';
import './SeatMap.css';

export default class SeatMap extends React.Component<any, any> {

  constructor(props) {
    super(props);
    this.state = {
      token: JSON.parse(window.localStorage.getItem('token') as string),
      map: {},
      settings: {
        loading: false,
        toast: false,
        toastMessage: null,
        highlight: false,
        popover: null,
      }
    };
  }

  getMap() {
    this.setState({
      settings: {
        loading: true,
      }
    })
    axios.get(
      'https://sitz.senn.com/api/v1/map/' + this.props.place,
      {
        headers: {
          'Authorization': 'Bearer ' + this.state.token.access_token,
          'Content-Type': 'application/json'
        }
      }
    ).then(res => {
      console.log(res.data);
      const map = res.data;
      this.setState({ map });
      this.setState({
        settings: {
          loading: false,
        }
      })
    })
  }

  changeHighlight(seat) {
    if (seat && seat.id) {
      if (!seat.enabled) {
        return;
      }
      //this.state.settings.highlight = seat.id
      //this.setState(this.state);
      this.props.refreshMap(this.props.today);
      this.getMap();
      console.log('change highlight', seat, seat.id, this.state.settings);
    }
  }

  async saveSeatForUser(seat) {
    this.setState({
      settings: {
        loading: true,
      }
    })
    console.log('save seat for user here')
    let vm = this;
    await axios.post(
      'https://sitz.senn.com/api/v1/changeseat',
      { seat: seat, today: this.props.today },
      {
        headers: {
          'Authorization': 'Bearer ' + this.state.token.access_token,
          'Content-Type': 'application/json'
        }
      }
    ).then(res => {
      this.setState({
        settings: {
          loading: false,
        }
      })
      //console.log('successfully .... res. ', res.data);
      vm.changeHighlight(res.data);
      //console.log(vm.props)
      // if (vm.props.today) {
      //   vm.props.history.push('/dashboard?tab=today')
      // } else {
      //   vm.props.history.push('/dashboard?tab=tomorrow')
      // }
      //window.location.reload()
    })
      .catch(function (error) {
        console.log(error.response)
        if (error.response.status === 422) {
          vm.state.settings.toastMessage = error.response.data.message;
          vm.state.settings.toast = true;
          vm.setState(vm.state);
        }

        console.log(error.toJSON());
      })
  }

  selectSeat(seat) {
    console.log('seat', seat)

    if (this.props.viewOnly && seat.enabled) {
      this.saveSeatForUser(seat);
    }

    seat.show = !seat.show;
    this.setState(this.state);
    if (this.props.viewOnly) {
      return;
    }

    this.props.selectSeat(seat)
  }

  componentDidMount() {
    console.log('seatmap mounted', this.props, this.props.today, !this.props.today)

    this.getMap()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.place !== this.props.place) {
      this.getMap()
    }
  }

  showPopover(value) {
    this.state.settings.popover = value;
    this.setState(this.state)
  }

  getHighlightedSeat(seat) {
    if (this.state.settings.highlight) {
      return seat.id == this.state.settings.highlight
    }
    return seat.id == this.props.highlight
  }

  closeToast() {
    this.state.settings.toast = false;
    this.setState(this.state);
  }

  showToast() {
    this.state.settings.toast = true;
    this.setState(this.state);
  }

  render() {
    return <div>

      <IonToast
        color="danger"
        isOpen={this.state.settings.toast}
        onDidDismiss={() => this.closeToast()}
        message={this.state.settings.toastMessage}
        duration={2000}
        position="top"
      />

      {this.state.map.plan &&
        <div style={{ position: 'relative' }}>
          <img src={'https://sitz.senn.com/storage/' + this.state.map.plan} className="w-full" />

          {!this.props.today &&
            <div>
              {this.state.map.seats.map((seat, index) => {
                return <div key={index} className={[(!seat.enabled || seat.booking.length > 0) ? 'seat seat-disabled' : "seat", (this.props.seat && seat.id == this.props.seat.id) ? 'seat-selected' : '', (this.getHighlightedSeat(seat)) ? 'seat-highlighted' : ''].join(' ')} style={{ top: seat.settings.height + '%', left: seat.settings.width + '%' }} onClick={() => this.selectSeat(seat)}>
                  {seat.name}
                  {(seat.booking.length > 0 && seat.show) &&
                    <div className="seat-popover">
                      {seat.booking[0]['user']['name']}
                    </div>
                  }
                  {(!seat.enabled && seat.show && seat.description) &&
                    <div className="seat-popover">
                      {seat.description}
                    </div>
                  }
                </div>
              })}
            </div>
          }

          {this.props.today &&
            <div>
              {this.state.map.seats.map((seat, index) => {
                return <div key={index} className={[(!seat.enabled || seat.today.length > 0) ? 'seat seat-disabled' : "seat", (this.props.seat && seat.id == this.props.seat.id) ? 'seat-selected' : '', (this.getHighlightedSeat(seat)) ? 'seat-highlighted' : 'not'].join(' ')} style={{ top: seat.settings.height + '%', left: seat.settings.width + '%' }} onClick={() => this.selectSeat(seat)}>
                  {seat.name}
                  {(seat.today.length > 0 && seat.show) &&
                    <div className="seat-popover">
                      {seat.today[0]['user']['name']}
                    </div>
                  }
                  {(!seat.enabled && seat.show && seat.description) &&
                    <div className="seat-popover">
                      {seat.description}
                    </div>
                  }
                </div>
              })}
            </div>
          }
        </div>
      }

      {this.state.settings.loading &&
        <div className="loader">
          <IonSpinner name="dots" />
        </div>
      }
    </div>;
  }
}
