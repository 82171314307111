import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonItem, IonLabel, IonInput, IonAvatar, IonButton } from '@ionic/react';
import React, { useState, useEffect, Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import './Style.css';
import axios from 'axios';
import { Plugins, PushNotification, PushNotificationToken, PushNotificationActionPerformed } from '@capacitor/core';
const { PushNotifications } = Plugins;

export default class Tab1 extends Component<any, any> {
  constructor(props: {}) {
    super(props);
    this.state = {
      token: JSON.parse(window.localStorage.getItem('token') as string),
      settings: {
        password: {},
        user: {},
        loading: false,
      }
    }
  }

  componentDidMount() {

    // console.log('Tab3 Did Mount')
    if (!this.state.token) {
      return;
    }

    this.getUser()
  }

  getUser() {
    let vm = this;

    axios.get(
      'https://sitz.senn.com/api/v1/user',
      {
        headers: {
          'Authorization': 'Bearer ' + this.state.token.access_token,
          'Content-Type': 'application/json'
        }
      }
    ).then(res => {
      this.state.settings.loading = false;
      this.state.settings.success = true;
      this.state.settings.user = res.data
      // console.log(res.data)
      this.setState(this.state);
    })
      .catch(function (error) {
        console.log(error)
        if (error.response.status === 401) {
        }
        vm.state.settings.error = true;
        window.localStorage.removeItem('token');
        vm.setState(vm.state);
      })
  }

  enablePushnotifications() {
    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register();

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: PushNotificationToken) => {
        console.log('Push registration success, token: ' + token.value);
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotification) => {
        console.log('Push received: ' + JSON.stringify(notification));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: PushNotificationActionPerformed) => {
        console.log('Push action performed: ' + JSON.stringify(notification));
        this.props.history.push('/buchung?edit=tomorrow')
      }
    );
  }

  getInitials(string) {
    var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }

  logout() {
    // console.log('logout');
    window.localStorage.removeItem('token');
    this.state.settings.error = true;
    this.setState(this.state);
  }

  handleChange(e) {
    this.state.settings.password.password = e.target.value;
  }

  saveProfile() {
    // console.log('save profile')
    this.state.settings.loading = true;
    this.setState(this.state);
    let vm = this;
    const input = { ...this.state.settings.user, ...this.state.settings.password }

    // console.log('input', input);

    axios.post(
      'https://sitz.senn.com/api/v1/user',
      input,
      {
        headers: {
          'Authorization': 'Bearer ' + this.state.token.access_token,
          'Content-Type': 'application/json'
        }
      }
    ).then(res => {
      this.state.settings.loading = false;
      this.state.settings.success = true;
      this.setState(this.state);
    })
      .catch(function (error) {
        console.log(error)
        if (error.response.status === 401) {
        }
        vm.state.settings.error = true;
        window.localStorage.removeItem('token');
        vm.setState(vm.state);

        console.log(error.toJSON());
      })
  }

  render() {
    if (!this.state.token || this.state.settings.error) {
      return <Redirect to='/login' push />
    }

    return (
      <>
        <IonPage>

          <IonContent>

            <div className="senn-header tab-2">
              <a className="senn-logo" href="/dashboard"><img src="/assets/sennlogo.svg" style={{ height: '116' }} width="116px" /></a>
            </div>

            <div className="content">
              <div className="profile-header">
                <IonAvatar className="profile-img">
                  {(this.state.settings.user && this.state.settings.user.name) &&
                    <span className="initials">{this.getInitials(this.state.settings.user.name)}</span>
                  }
                </IonAvatar>
              </div>

              <IonItem>
                <IonLabel position="floating">Name</IonLabel>
                <IonInput disabled value={this.state.settings.user.name}></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="floating">E-Mail</IonLabel>
                <IonInput disabled value={this.state.settings.user.email}></IonInput>
              </IonItem>

              <IonItem>
                <IonLabel position="floating">Passwort</IonLabel>
                <IonInput type="password" onInput={(e) => this.handleChange(e)}></IonInput>
              </IonItem>

              {/* <IonItem>
                <IonLabel position="floating" >Präferenz</IonLabel>
                <IonInput value={(this.state.settings.user.settings && this.state.settings.user.settings.preference) ? this.state.settings.user.settings.preference : null}></IonInput>
              </IonItem> */}

              {!this.state.settings.loading &&
                <IonButton className="mt-8 custom-button" color="success" expand="block" onClick={() => this.saveProfile()}>Speichern</IonButton>
              }

              {this.state.settings.loading &&
                <IonButton color="light" expand="block">Bitte warten...</IonButton>
              }

              <IonButton className="mt-8 custom-button" color="light" expand="block" onClick={() => this.enablePushnotifications()}>Benachrichtigungen erlauben</IonButton>

                 <IonButton className="mt-8 custom-button" color="danger" expand="block" fill="clear" href="https://sitz.senn.com/konto-loeschen">Konto löschen</IonButton>



              <IonButton className="mt-8 custom-button" color="medium" expand="block" fill="clear" onClick={() => this.logout()}>Abmelden</IonButton>

            </div>

          </IonContent>
        </IonPage>
      </>
    );
  }
};
