import {
  IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonButton, IonItem, IonAvatar, IonLabel, IonList, IonListHeader, IonPage, IonTitle, IonToolbar, IonRefresher, IonRefresherContent, IonIcon
} from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';
import { create } from 'ionicons/icons';
import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import './Style.css';
import SeatMap from './SeatMap';
import axios from 'axios';


const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export default class Tab1 extends Component<any, any> {
  constructor(props: {}) {
    super(props);

    this.props.history.listen((location, action) => {
      if (location.pathname === "/dashboard") {
        this.getUser()
      }
    });

    this.state = {
      token: JSON.parse(window.localStorage.getItem('token') as string),
      settings: {
        maps: null,
        user: null,
        tab: 'today',
        today: false,
        showMap: false,
        loading: true,
        edit: false,
        error: false,
        success: false
      },
      booking: {
        seat: null,
        place: null,
        morning: null,
        afternoon: null
      }
    }

    this.props.history.listen((location, action) => {
      if (this.props.history.location.search === "?tab=today") {
        // console.log('change tab today constructior', this.props.history.location)
        this.state.settings.tab = 'today';
      }
      if (this.props.history.location.search === "?tab=tomorrow") {
        // console.log('change tab TOMORRO constructior', this.props.history.location)
        this.state.settings.tab = 'tomorrow';
      }
    });


  }

  componentDidMount() {
    if (!this.state.token) {
      return;
    }
    if (this.isWeekend()) {
      this.state.settings.tab = 'tomorrow'; this.setState(this.state);
    }

    this.getUser();

    // console.log('change tab DIDMOUNT', this.props.history.location)

    if (this.props.history.location.search === "?tab=today") {
      this.state.settings.tab = 'today'; this.setState(this.state);
    }
    if (this.props.history.location.search === "?tab=tomorrow") {
      this.state.settings.tab = 'tomorrow'; this.setState(this.state);
    }
  }

  componentDidUpdate(prevProps) {
    //this.getUser();
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  getSeatHighlight(part) {
    if (part === 'morning') {
      if ((this.user().booking.booking.morning === 'zwicky')) {
        return this.user().booking.seats.find(function (element) {
          return element.workplace_id === 2;
        }).id
      }
      if ((this.user().booking.booking.morning === 'corso')) {
        return this.user().booking.seats.find(function (element) {
          return element.workplace_id === 1;
        }).id
      }
    }
    if (part === 'afternoon') {
      if ((this.user().booking.booking.afternoon === 'zwicky')) {
        return this.user().booking.seats.find(function (element) {
          return element.workplace_id === 2;
        }).id
      }
      if ((this.user().booking.booking.afternoon === 'corso')) {
        return this.user().booking.seats.find(function (element) {
          return element.workplace_id === 1;
        }).id
      }
    }
  }

  user() {
    return this.state.settings.user;
  }

  getInitials(string) {
    var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }

  getSeatHighlightToday(part) {
    if (part === 'morning') {
      if ((this.user().today.booking.morning === 'zwicky')) {
        return this.user().today.seats.find(function (element) {
          return element.workplace_id === 2;
        }).id
      }
      if ((this.user().today.booking.morning === 'corso')) {
        return this.user().today.seats.find(function (element) {
          return element.workplace_id === 1;
        }).id
      }
    }
    if (part === 'afternoon') {
      if ((this.user().today.booking.afternoon === 'zwicky')) {
        return this.user().today.seats.find(function (element) {
          return element.workplace_id === 2;
        }).id
      }
      if ((this.user().today.booking.afternoon === 'corso')) {
        return this.user().today.seats.find(function (element) {
          return element.workplace_id === 1;
        }).id
      }
    }
  }

  hasComplicatedMapToday() {
    return (this.user() && this.user().today && this.state.settings.tab === 'today' && this.user().today.place === 'it\'s complicated' && ((this.user().today.booking.morning == 'zwicky' || this.user().today.booking.morning == 'corso') || (this.user().today.booking.afternoon == 'zwicky' || this.user().today.booking.afternoon == 'corso')))
  }
  hasComplicatedMap() {
    return (this.user() && this.user().booking && this.state.settings.tab !== 'today' && this.user().booking.place === 'it\'s complicated' && ((this.user().booking.booking.morning == 'zwicky' || this.user().booking.booking.morning == 'corso') || (this.user().booking.booking.afternoon == 'zwicky' || this.user().booking.booking.afternoon == 'corso')))
  }

  getUser() {
    let vm = this;

    axios.get(
      'https://sitz.senn.com/api/v1/maps',
      {
        headers: {
          'Authorization': 'Bearer ' + this.state.token.access_token,
          'Content-Type': 'application/json'
        }
      }
    ).then(res => {
      this.state.settings.maps = res.data.maps
      this.state.settings.user = res.data.user
      this.state.settings.loading = false;
      this.state.settings.success = true;
      this.setState(this.state);
      // console.log('this.state', this.state);
    })
      .catch(function (error) {
        console.log(error)
        if (error.response.status === 401) {
        }
        vm.state.settings.error = true;
        window.localStorage.removeItem('token');
        vm.setState(vm.state);
      })
  }
  getTodaysID() {
    if (this.user().today.place === 'corso') {
      return 0;
    }

    if (this.user().today.place === 'zwicky') {
      return 1;
    }
  }

  getTomorrowsId() {
    if (this.user().booking.place === 'corso') {
      return 0;
    }

    if (this.user().booking.place === 'zwicky') {
      return 1;
    }
  }

  setPlace(place) {
    // console.log(place)
    this.state.booking.place = place;
    this.setState(this.state);
  }

  getToday() {
    return "Heute";
  }

  isWeekend() {
    var today = new Date();
    if (today.getDay() == 6 || today.getDay() == 0) {
      return true;
    }
    return false;
  }

  getTomorrow() {
    var today = new Date();

    if (today.getDay() == 6 || today.getDay() == 5 || today.getDay() == 0) {
      return 'Montag';
    }

    return "Morgen";
  }

  refresh = (event: CustomEvent<RefresherEventDetail>) => {
    this.getUser();
    setTimeout(() => {
      event.detail.complete();
    }, 500);
  }

  refreshMap(today) {
    this.getUser();
  }

  render() {
    if (!this.state.token || this.state.settings.error) {
      return <Redirect to='/login' />
    }

    const user = this.user();

    return (
      <>
        <IonPage>

          <IonContent>

            <IonRefresher slot="fixed" onIonRefresh={this.refresh}>
              <IonRefresherContent refreshingSpinner="dots"></IonRefresherContent>
            </IonRefresher>

            {user &&
              <div className="senn-header">
                <a className="senn-logo" href="/dashboard"><img src="/assets/sennlogo.svg" style={{ height: '116' }} width="116px" /></a>
                {user.today &&
                  <span className="uppercase font-bold mr-4" color={this.state.settings.tab === 'today' ? 'dark' : 'medium'} onClick={() => { this.state.settings.tab = 'today'; this.setState(this.state); }}>{this.getToday()}</span>
                }
                {user.booking &&
                  <span className="uppercase font-bold" color={this.state.settings.tab === 'tomorrow' ? 'dark' : 'medium'} onClick={() => { this.state.settings.tab = 'tomorrow'; this.setState(this.state); }}>{this.getTomorrow()}</span>
                }
              </div>
            }

            {(!this.isEmpty(user) && !user.today && !this.isWeekend()) &&
              <IonCard className="no-radius" color="default" button={true} onClick={() => this.props.history.push('/buchung?edit=today')}>
                <IonCardHeader>
                  <IonCardSubtitle>{this.getToday()}</IonCardSubtitle>
                  <IonCardTitle>
                    Noch kein Platz gebucht.
                    </IonCardTitle>
                  <IonIcon className="card-icon" icon={create}></IonIcon>
                </IonCardHeader>
              </IonCard>
            }

            {(!this.isEmpty(user) && !user.booking) &&
              <IonCard className="no-radius" color="default" button={true} onClick={() => this.props.history.push('/buchung?edit=tomorrow')}>
                <IonCardHeader>
                  <IonCardSubtitle>{this.getTomorrow()}</IonCardSubtitle>
                  <IonCardTitle>
                    Noch kein Platz gebucht.
                    </IonCardTitle>
                  <IonIcon className="card-icon" icon={create}></IonIcon>
                </IonCardHeader>
              </IonCard>
            }


            {(user && user.today && this.state.settings.tab === 'today') &&
              <IonCard className="no-radius" color="secondary" button={true} onClick={() => this.props.history.push('/buchung?edit=today')}>
                <IonCardHeader>
                  <IonCardSubtitle>{this.getToday()}</IonCardSubtitle>
                  <IonCardTitle className="card-title">
                    {(user.today.place !== 'it\'s complicated') &&
                      <div>
                        {user.today.place == 'corso' &&
                          <span>Am </span>
                        }
                        {user.today.place == 'zwicky' &&
                          <span>Im </span>
                        }
                        {capitalize(user.today.place)}
                      </div>
                    }
                    {(user.today.place === 'it\'s complicated') &&
                      <div>
                        <div className="card-title-seat">
                          {(user.today.booking.morning !== 'corso' && user.today.booking.morning !== 'zwicky') ?
                            <div>Du arbeitest am Morgen {user.today.booking.morning}.</div>
                            : <div>
                              <span>Du arbeitest am Morgen
                                {user.today.booking.morning == 'corso' &&
                                  <span> am </span>
                                }
                                {user.today.booking.morning == 'zwicky' &&
                                  <span> im </span>
                                }
                                {capitalize(user.today.booking.morning)} am Platz </span>
                              {(user.today.booking.morning === 'zwicky') &&
                                <span>{user.today.seats.find(function (element) {
                                  return element.workplace_id === 2;
                                }).name}</span>
                              }
                              {(user.today.booking.morning === 'corso') &&
                                <span>{user.today.seats.find(function (element) {
                                  return element.workplace_id === 1;
                                }).name}</span>
                              }.
                            </div>
                          }
                        </div>
                        <div className="card-title-seat">
                          {(user.today.booking.afternoon !== 'corso' && user.today.booking.afternoon !== 'zwicky') ?
                            <div>Du arbeitest am Nachmittag {user.today.booking.afternoon}.</div>
                            : <div>
                              <span>Du arbeitest am Nachmittag
                              {user.today.booking.afternoon == 'corso' &&
                                  <span> am </span>
                                }
                                {user.today.booking.afternoon == 'zwicky' &&
                                  <span> im </span>
                                }
                                {capitalize(user.today.booking.afternoon)} am Platz </span>
                              {(user.today.booking.afternoon === 'zwicky') &&
                                <span>{user.today.seats.find(function (element) {
                                  return element.workplace_id === 2;
                                }).name}</span>
                              }
                              {(user.today.booking.afternoon === 'corso') &&
                                <span>{user.today.seats.find(function (element) {
                                  return element.workplace_id === 1;
                                }).name}</span>
                              }.
                            </div>
                          }
                        </div>
                      </div>
                    }
                    {(user.today.booking && user.today.booking.seat && user.today.booking.seat.name) &&
                      <div className="card-title-seat">
                        Du arbeitest am Platz {user.today.booking.seat.name}.
                      </div>
                    }
                  </IonCardTitle>
                  <IonIcon className="card-icon" icon={create}></IonIcon>
                </IonCardHeader>
              </IonCard>
            }

            {(user && user.booking && this.state.settings.tab === 'tomorrow') &&
              <IonCard className="no-radius" color="secondary" button={true} onClick={() => this.props.history.push('/buchung?edit=tomorrow')}>
                <IonCardHeader>
                  <IonCardSubtitle>{this.getTomorrow()}</IonCardSubtitle>
                  <IonCardTitle className="card-title">
                    {(user.booking.place !== 'it\'s complicated') &&
                      <div>
                        {user.booking.place == 'corso' &&
                          <span>Am </span>
                        }
                        {user.booking.place == 'zwicky' &&
                          <span>Im </span>
                        }
                        {capitalize(user.booking.place)}
                      </div>
                    }
                    {(user.booking.place === 'it\'s complicated') &&
                      <div>
                        <div className="card-title-seat">
                          {(user.booking.booking.morning !== 'corso' && user.booking.booking.morning !== 'zwicky') ?
                            <div>Du arbeitest am Morgen {user.booking.booking.morning}.</div>
                            : <div>
                              <span>Du arbeitest am Morgen
                              {user.booking.booking.morning == 'corso' &&
                                  <span> am </span>
                                }
                                {user.booking.booking.morning == 'zwicky' &&
                                  <span> im </span>
                                }
                                {capitalize(user.booking.booking.morning)} am Platz </span>
                              {(user.booking.booking.morning === 'zwicky') &&
                                <span>{user.booking.seats.find(function (element) {
                                  return element.workplace_id === 2;
                                }).name}</span>
                              }
                              {(user.booking.booking.morning === 'corso') &&
                                <span>{user.booking.seats.find(function (element) {
                                  return element.workplace_id === 1;
                                }).name}</span>
                              }.
                            </div>
                          }
                        </div>
                        <div className="card-title-seat">
                          {(user.booking.booking.afternoon !== 'corso' && user.booking.booking.afternoon !== 'zwicky') ?
                            <div>Du arbeitest am Nachmittag {user.booking.booking.afternoon}.</div>
                            : <div>
                              <span>Du arbeitest am Nachmittag
                              {user.booking.booking.afternoon == 'corso' &&
                                  <span> am </span>
                                }
                                {user.booking.booking.afternoon == 'zwicky' &&
                                  <span> im </span>
                                }
                                {capitalize(user.booking.booking.afternoon)} am Platz </span>
                              {(user.booking.booking.afternoon === 'zwicky') &&
                                <span>{user.booking.seats.find(function (element) {
                                  return element.workplace_id === 2;
                                }).name}</span>
                              }
                              {(user.booking.booking.afternoon === 'corso') &&
                                <span>{user.booking.seats.find(function (element) {
                                  return element.workplace_id === 1;
                                }).name}</span>
                              }.
                            </div>
                          }
                        </div>
                      </div>
                    }
                    {(user.booking.booking && user.booking.booking.seat && user.booking.booking.seat.name) &&
                      <div className="card-title-seat">
                        Du arbeitest am Platz {user.booking.booking.seat.name}.
                      </div>
                    }
                  </IonCardTitle>
                  <IonIcon className="card-icon" icon={create}></IonIcon>
                </IonCardHeader>
              </IonCard>
            }
            {this.hasComplicatedMapToday() &&
              <div>
                <IonCard className="welcome-card no-radius map-card">
                  <IonCardContent>
                    <div>
                      {(user.today.booking.morning === 'corso' || user.today.booking.morning === 'zwicky') &&
                        <SeatMap place={user.today.booking.morning} highlight={this.getSeatHighlightToday('morning')} today={true} viewOnly={true} refreshMap={this.refreshMap.bind(this)} />
                      }
                      {(user.today.booking.afternoon === 'corso' || user.today.booking.afternoon === 'zwicky') &&
                        <SeatMap place={user.today.booking.afternoon} today={true} viewOnly={true} highlight={this.getSeatHighlightToday('afternoon')} refreshMap={this.refreshMap.bind(this)} />
                      }
                    </div>
                  </IonCardContent>
                </IonCard>
              </div>
            }

            {this.hasComplicatedMap() &&
              <div>
                <IonCard className="welcome-card no-radius map-card">
                  <IonCardContent>
                    <div>
                      {(user.booking.booking.morning === 'corso' || user.booking.booking.morning === 'zwicky') &&
                        <SeatMap place={user.booking.booking.morning} highlight={this.getSeatHighlight('morning')} today={false} viewOnly={true} refreshMap={this.refreshMap.bind(this)} />
                      }
                      {(user.booking.booking.afternoon === 'corso' || user.booking.booking.afternoon === 'zwicky') &&
                        <SeatMap place={user.booking.booking.afternoon} today={false} viewOnly={true} highlight={this.getSeatHighlight('afternoon')} refreshMap={this.refreshMap.bind(this)} />
                      }
                    </div>
                  </IonCardContent>
                </IonCard>
              </div>
            }

            {(user && user.today && this.state.settings.tab === 'today' && (user.today.place === 'corso' || user.today.place === 'zwicky')) &&
              <IonCard className="welcome-card no-radius map-card">
                <IonCardContent>
                  <div>
                    <SeatMap place={user.today.place} today={true} viewOnly={true} highlight={user.today.booking.seat.id} refreshMap={this.refreshMap.bind(this)} />
                  </div>
                </IonCardContent>
              </IonCard>
            }

            {(user && user.booking && this.state.settings.tab === 'tomorrow' && (user.booking.place === 'corso' || user.booking.place === 'zwicky')) &&
              <IonCard className="welcome-card no-radius map-card">
                <IonCardContent>
                  <div>
                    <SeatMap place={user.booking.place} today={false} viewOnly={true} highlight={user.booking.booking.seat.id} refreshMap={this.refreshMap.bind(this)} />
                  </div>
                </IonCardContent>
              </IonCard>
            }

            {(user && user.today && this.state.settings.maps && this.state.settings.maps.length > 0 && this.state.settings.tab === 'today' && this.state.settings.maps[this.getTodaysID()] && this.state.settings.maps[this.getTodaysID()].seats.length > 0) &&
              <IonList>
                <IonListHeader>
                  Belegung
                </IonListHeader>
                {this.state.settings.maps[this.getTodaysID()].seats.map((seat, index) => {
                  return <div key={index}>
                    {(seat.today.length > 0) &&
                      <IonItem>
                        <IonAvatar slot="start" className="belegung-initials">
                          {(seat.today[0]['user'] && seat.today[0]['user']['settings']) &&
                            <span className="belegung-initials-content">
                              {seat.today[0]['user']['settings']['initials'] ?? this.getInitials(seat.today[0]['user']['name'])}
                            </span>
                          }
                        </IonAvatar>
                        <IonLabel>
                          {(seat.today[0]['user']) &&
                            <h2>{seat.today[0]['user']['name']}</h2>
                          }
                          <p>{seat.name}</p>
                        </IonLabel>
                      </IonItem>
                    }
                  </div>
                })}
              </IonList>
            }

            {(user && user.booking && this.state.settings.maps && this.state.settings.maps.length > 0 && this.state.settings.tab === 'tomorrow' && this.state.settings.maps[this.getTomorrowsId()] && this.state.settings.maps[this.getTomorrowsId()].seats.length > 0) &&
              <IonList>
                <IonListHeader>
                  Belegung
                </IonListHeader>
                {this.state.settings.maps[this.getTomorrowsId()].seats.map((seat, index) => {
                  return <div key={index}>
                    {(seat.booking.length > 0) &&
                      <IonItem>
                        <IonAvatar slot="start">
                          {(seat.booking[0]['user'] && seat.booking[0]['user']['settings']) &&
                            <>
                              {seat.booking[0]['user']['settings']['initials'] ?? this.getInitials(seat.booking[0]['user']['name'])}
                            </>
                          }
                        </IonAvatar>
                        <IonLabel>
                          {(seat.booking[0]['user']) &&
                            <h2>{seat.booking[0]['user']['name']}</h2>
                          }
                          <p>{seat.name}</p>
                        </IonLabel>
                      </IonItem>
                    }
                  </div>
                })}
              </IonList>
            }

          </IonContent>
        </IonPage>
      </>
    );
  }
};
